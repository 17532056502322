<template>
  <div class="text-xs-center">
    <v-dialog v-model="getDialog" width="550">
      <v-form ref="formInvio" v-on:submit.prevent="invia()">
        <v-card>
          <v-card-title primary-title class="pt-1">
            <span class="headline pb-0">Promemoria conferma ordine:</span
            ><span class="title font-weight-light ml-2 mt-1">{{
              rowOrdine.OrdID
            }}</span>

            <span class="body-2 font-weight-light">
              {{ rowOrdine.CustName }}
            </span>
          </v-card-title>

          <v-card-text class="pt-0 pb-0">
            <v-text-field
              v-model="dati.destinatario"
              label="Destinatario"
              full-width
              hide-details
              maxlength="200"
              required
              filled
              :rules="[() => !!dati.destinatario || 'Il campo è obbligatorio']"
              readonly
            ></v-text-field>
            <v-divider class="py-2"></v-divider>
            <v-text-field
              v-model="dati.oggetto"
              label="Oggetto"
              full-width
              hide-details
              maxlength="200"
              required
              outline
              :rules="[() => !!dati.oggetto || 'Il campo è obbligatorio']"
            ></v-text-field>
            <v-divider class="py-2"></v-divider>

            <v-combobox
              v-model="dati.emailAgg"
              :hide-no-data="false"
              hide-selected
              label="E-Mail aggiuntive"
              multiple
              small-chips
              outline
              append-icon=""
              deletable-chips
              :rules="[rules.email]"
              required
              hide-details
            ></v-combobox>
            <v-divider class="py-2"></v-divider>
            <v-textarea
              v-model="dati.testo"
              label="Messaggio"
              counter
              maxlength="4000"
              required
              outline
              height="260"
              no-resize
              hide-details
              style="font-size: 12px"
              :rules="[() => !!dati.testo || 'Il campo è obbligatorio']"
            ></v-textarea>
          </v-card-text>

          <v-card-actions class="py-0 px-3" style="height: 90px">
            <v-btn color="primary" dark @click="chiudi">Annulla</v-btn>
            <v-spacer></v-spacer>

            <div
              class="elevation-4"
              style="
                height: 80px;
                width: 280px;
                padding: 5px;
                overflow: auto;
                font-size: 12px;
                border: 1px solid #bbb;
                border-radius: 0px;
              "
            >
              <span style="font-weight: bold"
                >Invii promemoria effettuati:</span
              >
              <div v-for="(item, i) in datiLog" :key="i">
                Inviato il {{ item.logDate }} da {{ item.sender }}
              </div>
            </div>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              type="submit"
              :disabled="attesa"
              :loading="attesa"
            >
              Invia
              <v-icon class="ml-2">send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  sendMail_confermaSollecito,
  get_testoSollecitoConferma,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, required: true, default: false },
    rowOrdine: { type: Object, default: () => ({}) },
  },

  data: () => ({
    dati: {
      destinatario: "",
      oggetto: "",
      testo: "",
      emailAgg: [],
      replyTo: "",
    },
    datiLog: [],
    attesa: false,

    rules: {
      required: (value) => !!value || "Il campo è obbligatorio.",
      email: (value) => {
        if (!!value.length == 0) return true;

        let res = false;
        // Ciclo sulle mail
        for (let i = 0; i < value.length; i++) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
          if (pattern.test(value[i]) == false)
            return "Indirizzo email non valido.";
        }
        return true;
      },
    },
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.dati.destinatario = "";
      this.dati.oggetto = "";
      this.dati.testo = "";
      this.dati.emailAgg = [];
      this.dati.replyTo = "";
      this.datiLog = [];

      if (this.dialog == false) return;

      this.$nextTick(() => this.$refs.formInvio.reset());

      try {
        let par;
        par = {
          IDFactory: this.rowOrdine.FactoryID,
          OrdID: this.rowOrdine.OrdID,
        };

        let res = await get_testoSollecitoConferma(par);

        this.dati.destinatario = res.data.sollecitoConferma.mailTO;
        this.dati.oggetto = res.data.sollecitoConferma.mailSubject;
        this.dati.testo = res.data.sollecitoConferma.mailText;
        this.dati.replyTo = res.data.sollecitoConferma.replayTO;
        this.getEmailAgg(res.data.sollecitoConferma.mailAggiuntive);
        this.datiLog = res.data.logConferma;

        // X DEBUG
        //this.dati.destinatario = "paolo@i2k.it";
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    getEmailAgg(mail) {
      if (mail != null && mail != "") {
        if (mail.includes("; ")) {
          let mails = mail.split("; ");
          mails.forEach((m) => {
            //escludo laura.rossa@aipporte.com perché interno
            if (m != "laura.rossa@aipporte.com") this.dati.emailAgg.push(m);
          });
        } else {
          if (mail != "laura.rossa@aipporte.com") this.dati.emailAgg.push(mail);
        }
      }
    },

    async invia() {
      // NOTA: la combo non viene validata subito, c'è un problema su onBlur
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(100); // Attendo la validazione della combo mail

      // Validazione
      if (this.$refs.formInvio.validate() == false) {
        return;
      }

      this.attesa = true;

      try {
        let par;
        par = {
          IDFactory: this.rowOrdine.FactoryID,
          IDBrand: this.rowOrdine.IDBrand,
          OrdID: this.rowOrdine.OrdID,
          MailTO: this.dati.destinatario,
          MailSubject: this.dati.oggetto,
          MailText: this.dati.testo,
          ReplayTO: "",
          MailAggiuntive: this.dati.emailAgg.join("|"),
        };

        let res = await sendMail_confermaSollecito(par);

        this.$toast.success("E-Mail inviata correttamente");

        // Chiudo la dialog
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },
};
</script>


