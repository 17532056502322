
import request from '@/services/interceptor.js'

// Utente
export const get_datiUserInfo = () => {
    return request({
        url: '/user/info',
        method: 'get',
        loadingBar: true
    })
};
export const get_datiprofiloUtente = () => {
    return request({
        url: '/user/dati/',
        method: 'get',
    })
};
export const save_datiprofiloUtente = (par) => {
    return request({
        url: '/user/save/',
        method: 'put',
        data: par
    })
};
export const get_help = () => {
    return request({
        url: '/user/help/',
        method: 'get',
    })
};
export const get_feedback = () => {
    return request({
        url: '/user/feedback/',
        method: 'get',
    })
};
export const send_feedback = (par) => {
    return request({
        url: '/user/feedback/',
        method: 'post',
        data: par
    })
};
export const log_activity = (par) => {
    return request({
        url: '/user/activity/' + par,
        method: 'post',
    })
};
