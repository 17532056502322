<template>
  <v-data-table
    :headers="headers"
    hide-default-header
    :items="datiWithIndex"
    :loading="attesa"
    :loading-text="'Caricamento...'"
    :no-data-text="''"
    :no-results-text="'Nessun dato trovato'"
    :options.sync="pagination"
    :disable-pagination="detail"
    :hide-default-footer="detail"
    item-key="id"
    ref="tabella"
    single-expand
    fixed-header
    :height="height"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    dense
    :footer-props="{ itemsPerPageText: '', pageText: '{0}-{1} di {2}' }"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
      :height="3"
    ></v-progress-linear>

    <!-- header -->
    <template v-slot:header="{ props: { headers } }">
      <thead>
        <tr>
          <th
            v-for="(head, i) in headers"
            v-show="head.hidden == false"
            :key="i"
            @click="changeSort(head)"
            :style="{
              width: +head.width + 'px',
              textAlign: head.align,
              cursor: head.sortable ? 'pointer' : 'default',
              backgroundColor: detail ? '#eee' : '#363636',
              color: detail ? '#000' : '#FFF',
            }"
          >
            <span v-html="head.text"></span>
          </th>
        </tr>
      </thead>
    </template>

    <!-- items -->
    <template v-slot:item="{ item, expand, isExpanded }">
      <tr
        style="vertical-align: middle !important; cursor: pointer !important"
        v-on:click="selectRow(item, expand, isExpanded)"
      >
        <td
          v-for="(header, i) in headers"
          style="padding: 2px !important"
          :style="header.style"
          v-show="header.hidden == false"
          :key="i"
        >
          <v-img
            v-if="header.dataType == 'Image'"
            :src="getImg(item[header.value], header.imageConditions)"
            v-tooltip="getTooltip(item, header)"
            height="30"
            contain
          />

          <v-icon
            v-else-if="header.dataType == 'V-Icon'"
            class="mr-2"
            v-on:click.stop="onClickHandler(header.onClick, item)"
            >{{ header.iconName }}</v-icon
          >

          <v-btn
            icon
            v-else-if="header.dataType == 'V-Btn'"
            class="mr-2"
            v-on:click.stop="checkCond(item, header, 'OnClick')"
            v-show="checkCond(item, header, 'IconName').length"
            v-tooltip="checkCond(item, header, 'ToolTipText')"
            :color="checkCond(item, header, 'Color')"
          >
            <v-icon>{{ checkCond(item, header, "IconName") }}</v-icon>
          </v-btn>

          <v-progress-circular
            v-else-if="header.dataType == 'V-Progress'"
            :rotate="-90"
            :size="header.size"
            style="font-size: 10px"
            :width="header.width"
            :value="item.PercCompl"
            :color="
              getColoreProgress(item.PercCompl, header.progressConditions)
            "
          >
            {{ header.label ? item.PercCompl : "" }}
          </v-progress-circular>

          <div
            v-else-if="header.dataType == 'Json'"
            v-html="getJson(item[header.value])"
            v-tooltip="getTooltip(item, header)"
          ></div>

          <div
            v-else
            v-tooltip="getTooltip(item, header)"
            v-html="item[header.value]"
          ></div>
          <!-- DA VEDERE  :style="{ width: header.width + 'px' }"-->
        </td>
      </tr>
    </template>

    <!-- Espansione -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <slot name="espansione" :id="item.id"></slot>
      </td>
    </template>
  </v-data-table>
</template>


<script>
import mixin from "@/mixin/mixinTable.js";

export default {
  mixins: [mixin],

  props: {
    attesa: { type: Boolean, default: false },
    height: { type: String, default: "" },
    detail: { type: Boolean, default: false },

    dati: { type: Array, default: () => [], required: true },
    stili: { type: Array, default: () => [], required: true },

    expansion: { type: Boolean, default: false },
    tabDettaglio: { type: Array, default: () => [] },
    datiDettaglio: { type: Array, default: () => [] },
    headersDettaglio: { type: Array, default: () => [] },
    attesaDettaglio: { type: Boolean, default: false },
  },

  data: () => ({
    headers: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },

    sortBy: "",
    sortDesc: false,

    selectedRow: {},
    tabActive: 0,
    indexRiga: -1,

    expanded: [],
  }),

  methods: {
    selectRow(item, expand, isExpanded) {
      //------
      this.selectedRow = item;

      this.indexRiga = item.id;

      this.$emit("rowExpansion", this.selectedRow);
      //------

      // Controllo se è abilitata l'espansione
      if (this.expansion == false) return;

      // Apro\chiudo
      expand(!isExpanded);

      // Controllo chiusura
      if (!isExpanded == false) return;

      // Alla prima espansione carico la prima tab
      this.onTabChanged();
    },

    onTabChanged() {
      this.$emit("tabChanged", this.tabDettaglio[this.tabActive]);
    },

    getRowSelected() {
      return this.selectedRow;
    },
    selectFirstRow() {
      this.selectRow(this.datiWithIndex[0]);
    },
    hideSelection() {
      this.indexRiga = -1;
    },
  },

  watch: {
    stili(val) {
      if (val) {
        this.headers = [];
        this.setJsonSettings(this.stili, this.headers);
      }
    },
    dati(val) {
      if (val) {
        // Reset
        this.selectedRow = [];
        this.indexRiga = -1;
      }
    },
  },

  computed: {
    // NOTA: serve per mettere un indice univoco alla riga "item-key" (nel caso di espansione è richiesto)
    datiWithIndex() {
      if (this.$refs.tabella) {
        // Chiudo eventuali righe aperte
        for (let i = 0; i <= this.dati.length - 1; i++) {
          this.$set(this.$refs.tabella.expanded, i, false);
        }
      }

      return this.dati.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  /*/ background-color: #363636 !important;*/
  /* color: #fff !important; */
  font-weight: normal;
}

.v-data-table tbody td:not(:last-child) {
  border-right: 1px solid lightgray;
}
</style>


