<template src="./activateAccount.html"></template>

<script>
import { account_activation, account_tokenCheck } from "@/api/api.account.js";
import loghi from "@/components/ui/Loghi.vue";

export default {
  components: {
    loghi
  },

  data: () => ({
    e1: true,
    alertElaborazione: "",
    tipo: "success",
    disabilitaBtn: false,
    attesaVerifica: false,

    cred: {
      Code: "",
      Password: ""
    }
  }),

  methods: {
    async verificaToken() {
      // Controllo querystring
      if (this.checkQueryStringCode() == false) {
        this.alertElaborazione = "Token non valido";
        this.tipo = "warning";
        return;
      }

      this.attesaVerifica = true;

      // Api
      try {
        this.cred.Code = this.$route.query.code;
        let res = await account_tokenCheck(this.cred);
      } catch (error) {
        // Token scaduto
        this.$router.push({ path: "/login" });
      }

      this.attesaVerifica = false;
    },

    async attiva() {
      // Validazione
      if (this.$refs.formPwd.validate() == false) {
        return;
      }

      this.cred.Code = this.$route.query.code;

      this.disabilitaBtn = true;
      this.alertElaborazione = "";

      try {
        // Attivazione
        let res = await account_activation(this.cred);

        // Vado alla pagina del login
        this.$router.push({ path: "/login" });

        // NOTA: uso il toast siccome passo alla pagina login
        this.$toast.success("Account attivato, eseguire il login per accedere");
      } catch (error) {
        this.tipo = "warning";
        this.alertElaborazione = this.$i2kFunctions.getErrorMsg(error);
      }

      this.disabilitaBtn = false;
    },

    checkQueryStringCode() {
      if (this.$route.query.code && this.$route.query.code.length > 0) {
        return true;
      }

      return false;
    }
  },

  created() {
    this.verificaToken();
  }
};
</script>

