

import request from '@/services/interceptor.js'

// Statistiche
export const get_statistiche = (par) => {
    return request({
        url: '/statistiche/' + par,
        method: 'get',
    })
};

export const get_filtriStat = (par) => {
    return request({
        url: '/statistiche/filtri/' + par,
        method: 'get',
    })
};

export const get_riepilogoAgentiStat = (par) => {
    return request({
        url: '/statistiche/riepilogo/agente/' + par,
        method: 'get',
    })
};

