import {
  get_datiUserInfo
} from "@/api/api.user.js";

const user = {
  namespaced: true,

  state: {
    user: '',
    role: '',
    menu: [],
    displayName: '',
    userId: 0,
  },

  mutations: {
    SET_USER: (state, user) => {

      state.user = user.username
      state.role = user.roleID
      state.menu = user.menu
      state.displayName = user.displayName
      state.userId = user.userId
    },

  },

  actions: {

    userInfo({
      commit,
      dispatch
    }) {

      return new Promise((resolve, reject) => {

        get_datiUserInfo().then(resp => {

          commit("SET_USER", resp.data)

          resolve(resp)

        }).catch(err => {

          reject(err)
        })
      })

    },


  },

  getters: {
    getRole: state => state.role,

    getTitolo: state => link => {

      let itemFind = state.menu.find(x => x.Link === link);
      if (itemFind)
        return itemFind.Text + '|' + itemFind.Img;

      return ""
    },

    getMenuId: state => link => {

      let itemFind = state.menu.find(x => x.link === link);
      if (itemFind)
        return itemFind.menuId;

      return ""
    },


    getNome: state => {
      return state.displayName || state.user
    },

    getUserId: state => {
      return state.userId
    },
  }
}


export default user