<template src="./fel.html">

</template>

<script>
import { get_fel, save_fel } from "@/api/api.fel.js";


export default {
  data: () => ({
    cred: {
      pec: "",
      codDestinatario: ""
    },
    returnMsg: "",
    disabilitaBtn: false,
    utente: "",
    tipoAlert: "success",
    azienda: "",
    attesa: false
  }),

  methods: {
    getUtente: function() {
      this.attesa = true;
      let user = this.$route.query.user;

      get_fel(user).then(
        res => {
          this.utente = res.data.ragSoc;
          this.azienda = res.data.factory;
          this.cred.pec = res.data.pecMail;
          this.cred.codDestinatario = res.data.codDest;

          this.attesa = false;
        },
        error => {
          console.log(error);
          this.attesa = false;
          this.returnMsg = "Account non valido";
          this.tipoAlert = "warning";
        }
      );
    },

    salva() {
      this.returnMsg = "";

      if (this.utente.trim().length == 0) {
        this.returnMsg = "Account non valido";
        this.tipoAlert = "warning";
        return;
      }

      // Validazione
      if (this.$refs.formLogin.validate() == false) {
        return false;
      }

      this.disabilitaBtn = true;

      let user = this.$route.query.user;

      save_fel(
        user + "/" + this.cred.pec + "/" + this.cred.codDestinatario
      ).then(
        res => {
          this.utente = res.data;
          this.disabilitaBtn = false;

          this.returnMsg = "Dati salvati correttamente.";
          this.tipoAlert = "success";
        },
        error => {
          console.log(error);
          this.disabilitaBtn = false;
        }
      );
    },

    checkQueryStringCode() {
      if (this.$route.query.user && this.$route.query.user.length > 0) {
        return true;
      }

      return false;
    },

    getLogo() {
      if (this.azienda == 1) return this.$load.getUrlImg() + "/logo_aip.png";
      else return this.$load.getUrlImg() + "/logo_stark.png";
    }
  },

  mounted() {
    // Controllo querystring
    if (this.checkQueryStringCode() == false) {
      this.returnMsg = "Account non valido";
      this.tipoAlert = "warning";
    } else this.getUtente();
  }
};
</script>


<style scoped>
.card {
  background-color: #ccc !important;
}

.account-wall {
  width: 500px;
  margin: 0 auto;
  margin-top: 30px;
}

.form-signin {
  padding: 15px;
  margin: 0 auto;
}

.form-signin,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.profile-img {
  margin: 20px auto 10px;
  display: block;
}
</style>