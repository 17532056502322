var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"justify-center":"","column":""}},[_c('v-card',{attrs:{"id":"toolbarCard"}},[_c('v-row',{staticClass:"ma-2"},[_c('div',{staticClass:"btnToolbar"},[_c('v-btn',{attrs:{"id":"pulsanteNuovo","dark":"","small":"","elevation":"0","icon":""},on:{"click":function($event){return _vm.caricaDati()}}},[_c('v-icon',{staticClass:"iconBtn"},[_vm._v(" refresh ")])],1)],1),_c('div',{staticClass:"btnToolbar"},[_c('v-btn',{attrs:{"id":"pulsanteNuovo","dark":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.apriDialogRicerca()}}},[_c('v-icon',{staticClass:"iconBtn"},[_vm._v(" add ")]),_vm._v(" NUOVO ")],1)],1),_c('v-spacer'),_c('div',{attrs:{"id":"search"}},[_c('v-text-field',{attrs:{"dark":"","append-icon":"search","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-container',[_c('v-layout',{attrs:{"justify-center":"","column":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-tabs',{attrs:{"color":"primary","slider-color":"primary","fixed-tabs":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{key:1},[_vm._v(" ANAGRAFICHE ")])],1)],1)],1)],1),(_vm.tabs==0)?_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headersAnagrafiche,"fixed-header":"","loading":_vm.loadingTabella,"items":_vm.anagrafiche,"page":_vm.page,"hide-default-header":"","hide-default-footer":"","loading-text":"Caricamento dati...","no-data-text":"Nessun dato disponibile"},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i,attrs:{"id":"headersAnagrafiche"}},[_c('span',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.raG_SOC_CF))]),_c('td',[_vm._v(_vm._s(item.indI_CF))]),_c('td',[_vm._v(_vm._s(item.caP_CF))]),_c('td',[_vm._v(_vm._s(item.comunE_CF))]),_c('td',[_vm._v(_vm._s(item.provinciA_CF))]),_c('td',[_vm._v(_vm._s(item.statO_CF))]),_c('td',[_vm._v(_vm._s(item.teL_CF))]),_c('td',[_vm._v(_vm._s(item.faX_CF))]),_c('td',[_vm._v(_vm._s(item.e_MAIL_CF))]),_c('td',[_vm._v(_vm._s(item.notE_CF))]),_c('td',[_vm._v(_vm._s(item.p_IVA_CF))]),_c('td',[_vm._v(_vm._s(item.coD_FISC_CF))]),_c('td',[_vm._v(_vm._s(item.cellularE_CF))]),_c('td',[_vm._v(_vm._s(item.raG_SOC_BREVE))]),_c('td',[_vm._v(_vm._s(item.e_MAIL_PEC))]),_c('td',[_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pt-0 pb-0",attrs:{"dense":""}},_vm._l((_vm.listaMenu),function(menu,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.azione(menu, item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(menu.icon)}}),_vm._v(_vm._s(menu.Nome)+" ")],1)],1)}),1)],1)],1)])]}}],null,false,2819675749)}):_vm._e()],1),_c('anagrafica',{attrs:{"dialog":_vm.dialogAnagrafica,"editAnagrafica":_vm.editAnagrafica,"modifica":_vm.modifica,"utente":_vm.utente},on:{"chiudi":_vm.chiudiDialogAnagrafica}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }