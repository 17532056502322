<template src="./statistiche.html"></template>


<script>
import {
  get_statistiche,
  get_filtriStat,
  get_riepilogoAgentiStat,
} from "@/api/api.statistiche.js";

import grafico from "./statistiche/Grafico.vue";
import autoComplete from "@/components/ui/Autocomplete";
import calendar from "@/components/ui/Calendar";
import vtable from "@/components/ui/VTable";

export default {
  components: {
    grafico,
    autoComplete,
    calendar,
    vtable,
  },

  data: () => ({
    dati: [],
    datiTabella: [],

    stiliTabella: [],

    agenteSel: {},
    agentiItems: [],

    clienteSel: {},
    clientiItems: [],

    radioBrand: "1",

    dataDa: new Date().getFullYear().toString() + "-" + "01" + "-" + "01",
    dataA: new Date().toISOString().substr(0, 10),

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      this.dati = [];
      this.attesa = true;

      let par = this.radioBrand + "?";

      par +=
        this.agenteSel && this.agenteSel.smCode
          ? "agente=" + this.agenteSel.smCode + "&"
          : "";
      par +=
        this.clienteSel && this.clienteSel.cuCode
          ? "cliente=" + this.clienteSel.cuCode + "&"
          : "";

      par += "dataInizio=" + this.dataDa + "&" + "dataFine=" + this.dataA;

      try {
        // Carico il dettaglio
        let res = await get_statistiche(par);

        this.dati = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      // NOTA: riepilogo attivo solo su role=1
      if (this.$store.getters["user/getRole"] == 1) {
        this.datiTabella = [];

        try {
          let res2 = await get_riepilogoAgentiStat(par);

          this.datiTabella = res2.data.dati;
          this.stiliTabella = res2.data.stili;
        } catch (error) {
          console.log(this.$i2kFunctions.getErrorMsg(error));
        }
      }

      this.attesa = false;
    },

    async caricaCombo() {
      try {
        let res = await get_filtriStat(this.radioBrand);

        this.agentiItems = res.data.agenti;
        this.clientiItems = res.data.clienti;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    aggiornaFiltri() {
      // reset
      this.agentiItems = [];
      this.clientiItems = [];
      this.agenteSel = {};
      this.clienteSel = {};

      this.caricaCombo();

      this.caricaDati();
    },

    roleFiltri(f) {
      let filtri = f.split("-");

      let r = this.$store.getters["user/getRole"];

      return !filtri.includes(r.toString());
    },
  },

  mounted() {
    this.caricaDati();

    this.caricaCombo();
  },

  computed: {
    getDati() {
      let arr = {
        dati1: this.dati.filter((f) => f.timePeriod == "CP"),
        dati2: this.dati.filter((f) => f.timePeriod == "PP"),
      };

      return arr;
    },

    getClienti() {
      let data = this.clientiItems;

      if (this.agenteSel && this.agenteSel.smCode)
        data = data.filter((f) => f.smCode == this.agenteSel.smCode);

      return data;
    },
  },
};
</script>
