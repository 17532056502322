<template>
  <div class="text-xs-center">
    <v-dialog v-model="getDialog" width="500" @keydown.esc="chiudi()">
      <v-form ref="formFeedback" v-on:submit.prevent="invia()">
        <v-card>
          <v-card-title class="headline" primary-title>Feedback</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="dati.oggetto"
              label="Oggetto"
              hide-details
              maxlength="200"
              required
              :rules="[
                () => dati.oggetto.length > 0 || 'Il campo è obbligatorio',
              ]"
            ></v-text-field>
            <v-divider></v-divider>
            <v-textarea
              v-model="dati.testo"
              label="Messaggio"
              counter
              maxlength="2000"
              single-line
              required
              :rules="[
                () => dati.testo.length > 0 || 'Il campo è obbligatorio',
              ]"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="chiudi">Annulla</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :disabled="attesa"
              :loading="attesa"
            >
              Invia
              <v-icon class="ml-2">send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { send_feedback } from "@/api/api.user.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    dati: { oggetto: "", testo: "" },
    attesa: false,
  }),

  methods: {
    async invia() {
      // Validazione
      if (this.$refs.formFeedback.validate() == false) {
        return;
      }

      this.attesa = true;

      let res = await send_feedback(this.dati);

      try {
        this.$toast.success("Feedback inviato.");

        // Chiudo la dialog
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {},

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },
};
</script>
