<template src="./feedback.html"></template>

<script>
import { get_feedback } from "@/api/api.user.js";

import vtable from "@/components/ui/VTable";

import Feedback from "@/components/dialog/FeedbackEdit";

export default {
  components: {
    vtable,
    Feedback
  },

  data: () => ({
    dati: [],
    stili: [],

    dialogFeed: false,

    attesa: false
  }),

  methods: {
    caricaDati() {
      this.attesa = true;

      // Reset
      this.dati = [];
      this.stili = [];

      get_feedback()
        .then(res => {
          this.dati = res.data.dati;
          this.stili = res.data.stili;

          this.attesa = false;
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.attesa = false;
        });
    },

    openDialog(nome) {
      this.dialogFeed = true;
    },
    chiudiDialog(nome) {
      this.dialogFeed = false;

      this.caricaDati();
    }
  },

  mounted() {
    this.caricaDati();
  }
};
</script>

