<template src="./download.html"></template>

<script>
//import { get_download } from "@/api/api.download.js";
import vtable from "@/components/ui/VTable";

export default {
  components: {
    vtable
  },

  data: () => ({
    dati: [],
    stili: [],

    attesa: false
  }),

  methods: {
    caricaDati() {
      // da togliere
      this.dati = [];
      return;

      //this.attesa = true;

      // get_download()
      //   .then(res => {
      //     this.dati = res.data;
      //     this.stili = res.data.stili;

      //     console.log(this.dati);

      //     this.attesa = false;
      //   })
      //   .catch(err => {
      //     console.log(this.$i2kFunctions.getErrorMsg(err));
      //     this.attesa = false;
      //   });
    }
  },

  mounted() {
    this.caricaDati();
  }
};
</script>
