<template>
  <div>
    <v-img
      :src="getPath() + '/logo_aip_white.png'"
      class="mb-2"
      contain
      aspect-ratio="5.25"
    >
    </v-img>

    <v-card
      class="elevation-0"
      style="margin: auto; border-radius: 0px"
      width="100%"
    >
      <v-container fluid fill-height class="px-3">
        <v-row justify="center">
          <v-col cols="3" class="pa-0">
            <v-img :src="getPath() + '/icon_STARK.png'" contain height="40">
            </v-img>
          </v-col>
          <v-col cols="2" class="pa-0">
            <v-img :src="getPath() + '/icon_TRIX.png'" contain height="40">
            </v-img>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-img
              :src="getPath() + '/icon_VISIONI.png'"
              contain
              width="80"
              height="40"
            >
            </v-img>
          </v-col>
          <v-col cols="3" class="pa-0">
            <v-img
              :src="getPath() + '/icon_SOLOLEGNO.png'"
              contain
              width="80"
              height="40"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!--  <v-layout
      row
      wrap
      style="height: 30px"
      class="ma-2 pa-0"
    >
      <v-flex xs3>
        <v-img
          :src="getPath() + '/icon_STARK.png'"
          contain
          width="80"
          height="30"
          style="margin-left: 10px"
        ></v-img>
      </v-flex>
      <v-flex xs2>
        <v-img
          :src="getPath() + '/icon_TRIX.png'"
          contain
          width="75"
          height="30"
        >
        </v-img>
      </v-flex>
      <v-flex xs4>
        <v-img
          :src="getPath() + '/icon_VISIONI.png'"
          contain
          width="90"
          height="30"
        >
        </v-img>
      </v-flex>
      <v-flex xs3>
        <v-img
          :src="getPath() + '/icon_SOLOLEGNO.png'"
          contain
          width="80"
          height="30"
          style="margin-left: -5px"
        >
        </v-img>
      </v-flex>
    </v-layout> -->
  </div>
</template>

<script>
export default {
  methods: {
    getPath() {
      return this.$load.getUrlImg();
    },
  },
};
</script>