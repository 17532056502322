<template>
  <v-menu
    :close-on-content-click="true"
    v-model="visData"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="190px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="formattedDate"
        @change="handleValue($event)"
        :label="titolo"
        prepend-icon="event"
        style="max-width: 150px !important"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dataPicker"
      locale="it-it"
      first-day-of-week="1"
      event-color="primary"
      class="b-date-picker"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    titolo: { type: String, default: "" },
    value: { type: null, required: true },
  },

  data: (vm) => ({
    dataPicker: vm.value,
    dataInit: vm.value,
    visData: false,
  }),

  methods: {
    formatDate_ISO(date) {
      if (!date) return null;

      // Converto dd/MM/yyyy => yyyy-MM-dd
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    formatDate_IT(date) {
      if (!date) return null;

      // Converto yyyy-MM-dd => dd/MM/yyyy
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    isDate(valore) {
      let d = new Date(valore);

      return d !== "Invalid Date" && !isNaN(d) ? true : false;
    },

    handleValue(v) {
      //console.log(v);

      let data = v;
      let dataSwap = v;

      // NOTA: serve nel caso abilito la modifica manuale (no readonly)
      if (data.includes("/")) {
        dataSwap = this.formatDate_ISO(data);
        //console.log(dataSwap);
      }

      // Se la data non è valida, ripristino su quella iniziale
      if (this.isDate(dataSwap) == false) data = this.dataInit;
      else data = dataSwap;

      this.$emit("input", data);
    },
  },

  computed: {
    formattedDate() {
      // Controllo in quale formato viene passata la data
      if (this.value.includes("/")) return this.value;

      return this.formatDate_IT(this.value);
    },
  },

  watch: {
    dataPicker(val) {
      this.handleValue(val);
    },
  },
};
</script>

