<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="700px"
    v-model="getDialog"
  >
    <v-card>
      <v-card-title primary-title>
        <v-row>
          <!-- Refresh -->
          <div class="mt-3">
            <v-btn @click="caricaDati()" fab small elevation="0">
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <!-- Search -->
          <div id="searchUtenti">
            <v-text-field
              color="#2196F3"
              label="Cerca"
              v-model="search"
              append-icon="search"
            ></v-text-field>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text id="tabellaClienti">
        <!-- Tabella Clienti Search -->
        <v-data-table
          v-if="flagCliente == false"
          :headers="headerTabellaSearch"
          fixed-header
          :search="search"
          :loading="loadingTabella"
          @page-count="pageCount = $event"
          :items="listaClienti"
          :page.sync="page"
          loading-text="Caricamento dati..."
          no-data-text="Nessun dato disponibile"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.numero }}</td>
              <td>{{ item.data }}</td>
              <td>{{ item.cliente }}</td>
              <td>{{ item.riferimento }}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- Tabella Clienti Cliente -->
        <v-data-table
          v-else
          :headers="headerTabellaClienti"
          fixed-header
          :search="search"
          :loading="loadingTabella"
          @page-count="pageCount = $event"
          :items="listaClienti"
          :page.sync="page"
          loading-text="Caricamento dati..."
          no-data-text="Nessun dato disponibile"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.cliente }}</td>
              <td>{{ item.localita }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions id="footer">
        <span v-if="flagCliente == false"> Record: {{ totNumRecord }} </span>
        <v-spacer></v-spacer>
        <!-- Pulsanti Azione -->
        <v-btn id="pulsantiAzione" dark @click="chiudi">ANNULLA</v-btn>
        <v-btn id="pulsantiAzione" dark>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    cliente: { type: Boolean, default: false },
  },

  data: () => ({
    totNumRecord: 0,
    utenti: [],
    search: "",
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    headerTabellaSearch: [
      { text: "Numero", value: "numero" },
      { text: "Data", value: "data" },
      { text: "Cliente", value: "cliente" },
      { text: "Riferimento", value: "riferimento" },
    ],
    headerTabellaClienti: [
      { text: "Cliente", value: "cliente" },
      { text: "Località", value: "localita" },
    ],
    listaClienti: [],
    flagCliente: false,
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;

      if (this.listaClienti.lenght > 0)
        this.totNumRecord = this.listaClienti.lenght;
      else this.totNumRecord = 0;
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {},

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },

    cliente() {
      this.flagCliente = this.cliente;
    },
  },
};
</script>

<style scoped src="..\..\..\assets\css\preventivi\dialog\cercaPreventivi.css">
</style>