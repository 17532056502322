<template src="./anagrafiche.html"></template>

<script>
import { get_anagrafiche, elimina_anagrafica } from "@/api/api.anagrafiche.js";
import anagrafica from "@/components/dialog/NuovaAnagrafica.vue";

export default {
  components: {
    anagrafica,
  },

  data: () => ({
    //tab
    tabs: 0,
    //tabella
    search: "",
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    //tabella
    anagrafiche: [],
    headersAnagrafiche: [
      { text: "Rag. Soc.", value: "raG_SOC_CF" },
      { text: "Indirizzo", value: "indI_CF" },
      { text: "CAP", value: "caP_CF" },
      { text: "Comune", value: "comunE_CF" },
      { text: "Provincia", value: "provinciA_CF" },
      { text: "Stato", value: "statO_CF" },
      { text: "Tel.", value: "teL_CF" },
      { text: "Fax", value: "faX_CF" },
      { text: "Email", value: "e_MAIL_CF" },
      { text: "Note", value: "notE_CF" },
      { text: "P. IVA", value: "p_IVA_CF" },
      { text: "Cos. Fisc.", value: "coD_FISC_CF" },
      { text: "Cellulare", value: "cellularE_CF" },
      { text: "Rag. Soc. Breve", value: "raG_SOC_BREVE" },
      { text: "PEC", value: "e_MAIL_PEC" },
      { text: "", value: "" },
    ],
    listaMenu: [
      { Nome: "Elimina", icon: "delete", id: 1 },
      { Nome: "Modifica", icon: "edit", id: 2 },
    ],
    //dialog anagrafica
    utente: 0,
    dialogAnagrafica: false,
    modifica: {},
    editAnagrafica: false,
  }),

  methods: {
    async caricaDati() {
      this.loadingTabella = true;

      let res = await get_anagrafiche();
      this.anagrafiche = res.data;

      this.loadingTabella = false;
    },

    /* ANAGRAFICA */
    apriDialogRicerca() {
      this.utente = this.$store.getters["user/getUserId"];
      this.dialogAnagrafica = true;
    },
    chiudiDialogAnagrafica() {
      this.dialogAnagrafica = false;
      this.caricaDati();
    },

    azione(menu, item) {
      if (menu.id == 1) {
        this.$confirm
          .open("Elimina", "Vuoi eliminare questa Anagrafica?")
          .then((confirm) => {
            if (!confirm) return;

            this.elimina(item);
          })
          .catch((err) => {
            alert("err");
          });
      } else if (menu.id == 2) {
        this.editAnagrafica = true;
        this.modifica = item;
        this.apriDialogRicerca();
      }
    },

    async elimina(item) {
      this.loadingTabella = true;

      let res = await elimina_anagrafica(item);
      if (res.data == "OK") {
        this.$toast.info("Dati eliminati");
        this.caricaDati();
      } else this.$toast.alert("Impossibile eliminare i dati.");

      this.loadingTabella = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  created() {},

  watch: {},

  computed: {},
};
</script>

<style scoped src="..\assets\css\preventivi\anagrafica.css">
</style>