<template>
    <v-container fluid v-if="datiOrdine">

        <v-dialog v-model="dialogConferma" width="550" persistent>
            <dialog-conferma-ordine :dialog="dialogConferma" @chiudi="chiudiConferma" :factoryId="1"
                :docId="datiOrdine.DOC_ID" :crm="false" @confermato="handleOrdineConf" />

            <!-- <dialogConfermaOrdineAuth v-else :dialog="dialogConferma" @chiudi="chiudiConferma" :factoryId="1"
                :docId="datiOrdine.DOC_ID" :crm="false" @confermato="handleOrdineConf" /> -->
        </v-dialog>

    </v-container>
</template>


<script>
import { get_info_ordine } from "@/api/api.ext.js";
import dialogConfermaOrdine from "./DialogConfermaOrdine.vue";
/* import dialogConfermaOrdineAuth from "@/components/dialog/ConfermaOrdine.vue"; */

export default {
    components: {
        dialogConfermaOrdine/* , dialogConfermaOrdineAuth */
    },
    data: () => ({
        numOrdine: "",
        reference: "",
        datiOrdine: {},
        dialogConferma: false,
        auth: false,
    }),
    methods: {
        async caricaDati() {
            let par = "?codice=" + encodeURIComponent(this.numOrdine) + "&reference=" + encodeURIComponent(this.reference);
            let res = await get_info_ordine(par);
            if (res.data.message == "OK")
                this.datiOrdine = res.data.info;
            else {
                this.$toast.alert(res.data.message);
                await this.wait(2000);
                this.$router.push("/");
                return;
            }

            if (this.datiOrdine.length == 0) {
                this.$toast.alert("Ordine non valido");
                await this.wait(2000);
                this.$router.push("/");
                return;
            }
            this.dialogConferma = true
        },

        checkNumOrdine() {
            if (this.numOrdine == null || this.numOrdine == undefined || this.numOrdine == "") return false;
            return true;
        },

        async wait(time) {
            let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
            await wait(time);
        },

        chiudiConferma() {
            this.dialogConferma = false;
        },

        async handleOrdineConf(val) {
            if (val == true)
                this.$toast.info("Ordine confermato");
            else
                this.$toast.alert("Ordine non confermato");

            await this.wait(2000);
            this.dialogConferma = false;
            this.$router.push("/");
        },
    },

    async mounted() {
        // salvo il numero dell'ordine criptato
        this.numOrdine = this.$route.query.codice;
        this.reference = this.$route.query.reference;
        if (!this.checkNumOrdine()) {
            await this.wait(500);
            this.$router.push("/");
            return;
        }
        this.auth = this.$store.getters['auth/isAuthenticated'];

        this.caricaDati();
    },
}
</script>