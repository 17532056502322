<template >
  <!-- Grafico -->
  <chart :data="chartData" :options="options" v-if="loaded"></chart>
</template>


<script>
import Chart from "./chart/BarChart.js";

export default {
  components: {
    Chart,
  },

  props: {
    dati: { type: Object, required: true },
  },

  data: (e) => ({
    chartData: {
      labels: [],

      datasets: [
        {
          label: "Periodo Corrente",
          backgroundColor: [],
          data: [],
        },
        {
          label: "Periodo Precedente",
          backgroundColor: [],
          data: [],
        },
      ],
    },

    options: {
      //responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "Totale ordinato",
      },
      plugins: {
        datalabels: {
          color: "#000",
          font: { size: "14" },
          anchor: "end",
          align: "top",
          formatter: function (value, context) {
            return "€ " + value.toLocaleString();
          },
          rotation: -90,
        },
      },
      scales: {
        yAxes: [
          {
            //minBarLength: 5,
            display: false,
            stacked: false,
            ticks: {
              min: 0,
            },
          },
        ],
        xAxes: [
          {
            autoSkip: false,
            gridLines: {
              offsetGridLines: true,
            },

            ticks: { fontSize: 13, fontColor: "#000", fontStyle: "bold" },
          },
        ],
      },
    },

    loaded: false,
  }),

  methods: {
    caricaDati() {
      //console.log("carica dati")

      // Reset
      this.chartData.datasets[0].data = [];
      this.chartData.labels = [];

      // NOTA: The most common problem is, that you mount your chart component directly and pass in data from an async API call.
      // The problem with this approach is, that chart.js tries to render your chart and access the chart data,
      // but your API call  is async. So you chart mounts before your data arrives.
      // To prevent this, a simple v-if is the best solution.
      this.loaded = false;

      let coloreCorrente, colorePrec;

      // Ciclo sui dati
      for (let i in this.dati.dati1) {
        //console.log(this.dati.dati1[i])

        // Barre asse y
        this.chartData.datasets[0].data.push(this.dati.dati1[i].sellValue);
        this.chartData.datasets[1].data.push(this.dati.dati2[i].sellValue);

        // Label asse x
        this.chartData.labels.push([
          this.dati.dati1[i].brand,
          "",
          this.dati.dati1[i].trend > 0 ? "Incremento" : "Perdita",

          (this.dati.dati1[i].trend > 0
            ? "+ " + this.dati.dati1[i].trend
            : this.dati.dati1[i].trend) + " %",
          this.dati.dati1[i].deltaValue > 0
            ? "+ " + this.dati.dati1[i].deltaValue.toLocaleString() + " €"
            : this.dati.dati1[i].deltaValue.toLocaleString() + " €",
        ]);

        colorePrec = "#86c7f3";

        if (this.dati.dati1[i].sellValue > this.dati.dati2[i].sellValue) {
          coloreCorrente = "#74cc49";
        } else {
          coloreCorrente = "#e55b5b";
        }

        this.chartData.datasets[0].backgroundColor.push(coloreCorrente);
        this.chartData.datasets[1].backgroundColor.push(colorePrec);
      }

      this.chartData.datasets[0].borderWidth = 3;

      this.loaded = true;
    },
  },

  mounted() {
    this.caricaDati();
  },
};
</script>

