// Debug 
const url_debug = 'http://localhost:56036';       // debug
//const url_debug = "http://10.0.22.4:84"           // intranet
//const url_debug = 'http://crmtest.aipporte.com';  // internet test
//const url_debug = 'http://crmdemo.aipporte.com';  // internet demo
//const url_debug = "https://crm.aipporte.com"        // Internet

// Debug img
//const url_debugImg = "http://crmtest.aipporte.com"
const url_debugImg = "http://crm.aipporte.com"



export default {

  getUrl() {
    if (window.location.host.includes("localhost"))
      return url_debug

    return window.location.protocol + "//" + window.location.host
  },




  getUrlApi() {

    let url = this.getUrl();

    if (url.includes("localhost"))
      url += "/api"
    else
      url += "/api/api"

    return url
  },

  getUrlImg() {

    let url = this.getUrl();

    // NOTA: se uso il localhost, devo agganciare le immagini dal server
    if (url.includes("localhost"))
      url = url_debugImg

    url += "/risorse/img"

    return url
  },

  getUrl_Update() {

    let url = this.getUrl();

    if (window.location.host.includes("localhost"))
      url = ""
    else
      url += "/index.html"

    return url
  }


}