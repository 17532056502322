

import request from '@/services/interceptor.js'

// Account
export const send_passwordMail = (par) => {
    return request({
        url: '/account/password/mail',
        method: 'post',
        data: par
    })
};

export const save_newPassword = (par) => {
    return request({
        url: '/account/password/reset',
        method: 'post',
        data: par
    })
};

export const get_activationList = (par) => {
    return request({
        url: '/account/activation/list',
        method: 'get',
    })
};

export const mail_activation = (par) => {
    return request({
        url: '/account/activation/mail',
        method: 'post',
        data: par
    })
};


export const account_activation = (par) => {
    return request({
        url: '/account/activation/user',
        method: 'post',
        data: par
    })
};

export const account_tokenCheck = (par) => {
    return request({
        url: '/account/activation/checktoken',
        method: 'post',
        data: par
    })
};
