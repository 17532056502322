import {
    login_user,
    logout_user,
    refresh_token
} from "@/api/api.auth.js";

import storage from "@/services/storage.js"


const getState = () => {
    return {
        token: storage.getToken(),
        refToken: storage.getRefToken(),
        dataExpired: storage.getDataExpiredToken(),
        remember: !!storage.getRefToken(),
    }
}

const auth = {
    namespaced: true,

    state: getState(),

    mutations: {

        SET_TOKEN: (state, data) => {

            // Imposto lo storage
            storage.setStorage(data)

            // Aggiorno lo stato (prende i dati dello storage, impostato prima)
            Object.assign(state, getState())

        },
    },



    actions: {


        // ------ LOGIN -------
        login({
            commit,
            dispatch
        }, user) {


            return new Promise((resolve, reject) => {

                login_user({
                    grant_type: 'password',
                    username: user.username,
                    password: user.password
                }).then(resp => {

                    commit("SET_TOKEN", resp.data)

                    resolve(resp)

                }).catch(err => {

                    reject(err)
                })
            })
        },

        // ------ LOGOUT -------
        logout({
            commit,
            state
        }) {

            return new Promise((resolve, reject) => {

                logout_user({
                    refresh_token: storage.getRefToken()
                }).then(resp => {

                    // Ritorno al login
                    storage.logout()

                    resolve(resp)

                }).catch(err => {

                    // NOTA: in caso di errore esco comunque
                    storage.logout()

                    reject(err)
                })
            })



        },

        // ------ REFRESH TOKEN -------
        refreshToken({
            commit,
            dispatch
        }, user) {

            return new Promise((resolve, reject) => {

                refresh_token({
                    grant_type: 'refresh_token',
                    refresh_token: storage.getRefToken()
                }).then(resp => {

                    commit("SET_TOKEN", resp.data)

                    resolve(resp)

                }).catch(err => {

                    reject(err)
                })
            })
        },

    },

    getters: {
        isAuthenticated: state => !!state.token,
    }


}


export default auth