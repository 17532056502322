<template>
  <v-app-bar
    color="primary"
    dark
    app
    :clipped-left="$vuetify.breakpoint.mdAndUp"
    fixed
    class="elevation-3"
    dense
  >
    <v-app-bar-nav-icon @click.stop="leftBar()"></v-app-bar-nav-icon>

    <v-app-bar-title class="label">
      <span class="font-weight-light mr-5" style="font-size: 20px"
        >Area riservata</span
      >
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <!-- Attesa -->
    <v-progress-circular
      :size="40"
      color="white"
      indeterminate
      v-if="loading"
    ></v-progress-circular>

    <v-spacer></v-spacer>

    <!-- Utente -->
    <v-toolbar-title style="font-size: 16px; font-weight: normal">{{
      utente
    }}</v-toolbar-title>

    <!-- Menu utente -->
    <v-menu
      bottom
      offset-y
      :close-on-click="true"
      :close-on-content-click="true"
      :nudge-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon large>account_circle</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon :x-large="true">account_circle </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Utente :</v-list-item-title>
              <v-list-item-subtitle>{{ utente }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="disabilitaBtn"
            color="primary"
            v-on:click="account"
            dark
          >
            <v-icon center dark>person</v-icon>Account
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="disabilitaBtn"
            v-on:click="esci"
            color="primary"
            dark
          >
            <v-icon center dark>exit_to_app</v-icon>Esci
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>



<script>
export default {
  props: {
    utente: { type: String, default: "" },
    loading: { type: Boolean, default: false },
  },

  data: () => ({
    disabilitaBtn: false,
  }),

  methods: {
    esci() {
      this.disabilitaBtn = true;

      this.$emit("esci");
    },

    leftBar() {
      this.$emit("showLeftBar");
    },

    account() {
      this.$router.push({ path: "/profilo" });
    },

    getPath() {
      return this.$load.getUrlImg();
    },
  },
};
</script>