<template src="./main.html"></template>


<script>
import { mapState } from "vuex";

import LeftBar from "@/components/layout/LeftBar";
import ToolBar from "@/components/layout/ToolBar";

export default {
  components: {
    LeftBar,
    ToolBar,
  },

  data: () => ({
    menu: [],
    disabilitaBtn: false,
  }),

  methods: {
    async init() {
      // User info

      try {
        let resp = await this.$store.dispatch("user/userInfo");

        this.menu = resp.data.menu;
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));

        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async esci() {
      this.disabilitaBtn = true;

      try {
        await this.$store.dispatch("auth/logout");
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },
    showLeftBar() {
      this.$refs.leftBar.leftBarVis = !this.$refs.leftBar.leftBarVis;
    },
  },

  mounted() {
    this.init();
  },

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
      message: (state) => state.app.message,
    }),

    utente() {
      return this.$store.getters["user/getNome"];
    },
  },

  watch: {
    message() {
      this.$toast.alert(this.message);
    },
  },
};
</script>
