

export default {

  getToken() {
    return localStorage.getItem('token')
  },

  getRefToken() {
    return localStorage.getItem('ref_token')
  },

  getDataExpiredToken() {
    return localStorage.getItem('exp_data')
  },

  getAuthHeader() {
    return 'Bearer ' + this.getToken()
  },


  checkRemember() {
    return !!localStorage.getItem('ref_token')
  },

  checkAuth() {
    return !!localStorage.getItem('token')
  },




  clearStorage() {

    localStorage.clear();
  },


  logout() {

    // Puliza storage
    this.clearStorage()

    // Pulisce lo state perchè ricarica la pagina
    window.location = '/login'

  },

  setStorage(data) {

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('exp_data', data.expires_in)
    localStorage.setItem('ref_token', data.refresh_token)
  },

  setVersion(data) {
    localStorage.setItem('version', data);
  },

  getVersion() {
    return localStorage.getItem('version')
  }

}
