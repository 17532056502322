<template>
  <v-app>
    <v-main>
      <router-view v-if="waitForNewVersione == false" />
    </v-main>

    <!-- Plugin -->
    <toast></toast>
    <confirm></confirm>
    <wait></wait>
    <passwordInput></passwordInput>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    waitForNewVersione: true,
  }),

  methods: {
    async checkUpdate() {
      // Update
      await this.$i2kFunctions.checkUpdate(this.$load.getUrl_Update(), true);

      this.waitForNewVersione = false;
    },
  },

  created() {
    this.checkUpdate();
  },
};
</script>

