<template>
  <v-autocomplete
    :items="items"
    :label="label"
    :item-text="itemText"
    return-object
    :loading="attesa"
    clearable
    :value="value"
    @change="$emit('input', $event)"
  ></v-autocomplete>
</template>

<script>

export default {
  props: {
    value: {},
    items: { type: Array, required: true },
    label: { type: String, required: true },
    itemText: { type: String, required: true }
  },

  data: () => ({
    attesa: false
  }),


};
</script>

