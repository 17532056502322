<template src="./attivazioneUtenti.html"></template>

<script>
import attivaUtente from "@/components/dialog/AttivaUtente.vue";
import { get_activationList } from "@/api/api.account.js";
import vtable from "@/components/ui/VTable";

export default {
  components: {
    attivaUtente,
    vtable,
  },
  data: () => ({
    dati: [],
    stili: [],

    attesa: false,

    filtroCliente: "",
    filtriAziende: ["Tutti", "Aip", "Stark", "Comuni"],
    filtroAziendaSel: "Tutti",
    attivi: false,

    dialogAttivazione: false,
    piva: "",
    nome: "",
  }),

  methods: {
    async caricaDati() {
      this.attesa = true;

      // Reset
      this.dati = [];
      this.stili = [];

      try {
        let res = await get_activationList();

        this.dati = res.data.dati;
        this.stili = res.data.stili;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    mailAttivazione(item) {
      this.dialogAttivazione = true;
      this.piva = item.CustVat;
      this.nome = item.CustomerName;
    },

    chiudiDialogAttivazione() {
      this.dialogAttivazione = false;
    },

    onClick(handler, data) {
      if (handler == "mailAttivazione") this.mailAttivazione(data);
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getFiltroDati() {
      let arr = this.dati;

      if (this.filtroAziendaSel == "Aip")
        arr = arr.filter((x) => x.CustType == "OnlyF1");
      else if (this.filtroAziendaSel == "Stark")
        arr = arr.filter((x) => x.CustType == "OnlyF2");
      else if (this.filtroAziendaSel == "Comuni")
        arr = arr.filter((x) => x.CustType == "Common");

      if (this.attivi) arr = arr.filter((x) => x.Enabled == true);

      // Numero Ordine
      if (this.filtroCliente)
        arr = arr.filter((x) =>
          x.CustomerName.toLowerCase().includes(
            this.filtroCliente.toLowerCase()
          )
        );

      return arr;
    },
  },
};
</script>
