
import request from '@/services/interceptor.js'

// Ordini
export const get_ordini = (par) => {
    return request({
        url: '/ordini/dati/' + par,
        method: 'get'
    })
};
export const get_ordiniDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/' + par,
        method: 'get'
    })
};
export const sendMail_ordine = (par) => {
    return request({
        url: '/ordini/mail',
        method: 'post',
        data: par
    })
};





// Ordini da confermare
export const get_ordiniDaConf = (par) => {
    return request({
        url: '/ordini/daconfermare/' + par,
        method: 'get'
    })
};

export const confermaOrdine = (data) => {
    return request({
        url: '/ordini/conferma',
        method: 'post',
        data: data
    })
};

export const get_datiConfermaOrdine = (par) => {
    return request({
        url: '/ordini/conferma/' + par,
        method: 'get'
    })
};

export const attivaConferma = (data) => {
    return request({
        url: '/ordini/conferma/attiva',
        method: 'post',
        data: data
    })
};

export const get_dettaglioConferma = (par) => {
    return request({
        url: '/ordini/conferma/dettaglio/' + par,
        method: 'get'
    })
};

export const get_testoSollecitoConferma = (par) => {
    return request({
        url: '/ordini/conferma/testosollecito',
        method: 'post',
        data: par
    })
};

export const sendMail_confermaSollecito = (par) => {
    return request({
        url: '/ordini/conferma/mail',
        method: 'post',
        data: par
    })
};

