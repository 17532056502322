<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="400px"
    v-model="getDialog"
  >
    <v-card>
      <v-card-title id="toolbar">
        <v-row>
          <!-- Close -->
          <div>
            <v-icon id="close" @click="chiudi()" dark fab elevation="0"
              >close</v-icon
            >
          </div>
          <!-- Title -->
          <div>
            <span id="title">Info testata</span>
          </div>
          <v-spacer></v-spacer>
          <!-- Salva -->
          <div>
            <v-btn id="btn_salva" dark elevation="0">
              SALVA
              <v-icon class="ml-1" dark> save </v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text id="corpoDialog">
        <!-- Data -->
        <v-row class="mt-5">
          <div id="filtroData">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  color="#2196F3"
                  v-model="dataIT"
                  label="Data"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#2196F3"
                v-model="date"
                @input="menu = false"
                locale="it-it"
                :first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-row>
        <!-- Riferimento -->
        <v-row>
          <v-text-field
            v-model="Cantiere.Riferimento"
            color="#2196F3"
            outlined
            label="Riferimento"
          ></v-text-field>
        </v-row>
        <!-- Note -->
        <v-row>
          <v-textarea
            outlined
            auto-grow
            rows="4"
            row-height="30"
            v-model="Cantiere.Note"
            color="#2196F3"
            label="Note"
          ></v-textarea>
        </v-row>
        <!-- Destinazione -->
        <v-row>
          <v-text-field
            v-model="Cantiere.Destinazione"
            color="#2196F3"
            outlined
            label="Destinazione consegna (se diversa da sede)"
          ></v-text-field>
        </v-row>
        <!-- Comune -->
        <v-row>
          <v-select
            :items="Comuni"
            v-model="Cantiere.Comune"
            color="#2196F3"
            outlined
            label="Comune cantiere"
          ></v-select>
        </v-row>
        <!-- Tipologia -->
        <v-row>
          <v-select
            :items="Lavori"
            v-model="Cantiere.Tipologia"
            color="#2196F3"
            outlined
            label="Tipologia lavoro"
          ></v-select>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    Cantiere: {
      Data: "",
      Riferimento: "",
      Note: "",
      Destinazione: "",
      Comune: "",
      Tipologia: "",
      Climatica: "",
      Limite: "",
    },
    Comuni: [],
    Lavori: [],
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {},

  computed: {
    dataIT() {
      const [year, month, day] = this.date.split("-");
      return `${day.substring(0, 2)}/${month}/${year}`;
    },

    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
    dataIT() {
      this.Cantiere.Data = this.dataIT;
    },
  },
};
</script>

<style scoped src="..\..\..\assets\css\preventivi\dialog\infoTestata.css">
</style>