import { render, staticRenderFns } from "./anagrafiche.html?vue&type=template&id=80a373d8&scoped=true&"
import script from "./Anagrafiche.vue?vue&type=script&lang=js&"
export * from "./Anagrafiche.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/preventivi/anagrafica.css?vue&type=style&index=0&id=80a373d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80a373d8",
  null
  
)

export default component.exports