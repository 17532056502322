<template src="./ordiniDaConfermare.html"></template>

<script>
import {
  get_ordiniDaConf,
  attivaConferma,
  get_dettaglioConferma,
} from "@/api/api.ordini.js";

import mixin from "@/mixin/mixinTable.js";

import vtable from "@/components/ui/VTable";
import confermaOrdine from "@/components/dialog/ConfermaOrdine.vue";
import sollecito from "@/components/dialog/Sollecito.vue";
import attivaUtente from "@/components/dialog/AttivaUtente.vue";

export default {
  mixins: [mixin],

  components: {
    vtable,
    confermaOrdine,
    sollecito,
    attivaUtente,
  },

  data: () => ({
    datiTestata: [],
    datiDettaglio: [],
    stili: [],
    stiliDettaglio: [],
    // headersDettaglio: [],
    selectedRow: [],
    espandi: false,
    tabDettaglio: [],

    attesaTestata: false,
    attesaDettaglio: false,

    filtriAziende: ["Tutti", "SoloLegno", "Stark", "Trix", "Visioni"],
    filtroAziendaSel: "Tutti",

    filtroDataConsegna: "",
    filtroNumOrdine: "",
    filtroCliente: "",

    dialogConferma: false,
    rowConferma: {},
    dialogSollecito: false,
    dialogAttivazione: false,
    piva: "",
    nome: "",
  }),

  methods: {
    async caricaDati() {
      this.attesaTestata = true;

      // Reset
      this.datiTestata = [];
      this.datiDettaglio = [];
      this.espandi = false;
      this.tabDettaglio = [];
      this.rowConferma = {};
      this.ordineConf = false;

      let filtro = this.filtriAziende.indexOf(this.filtroAziendaSel) - 1;
      filtro += this.$route.path;

      try {
        let res = await get_ordiniDaConf(filtro);

        this.datiTestata = res.data.dati;
        this.stili = res.data.stili;
        this.espandi = !!res.data.toExpand;
        this.tabDettaglio = res.data.tabs;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaTestata = false;
    },

    onClick(handler, data) {
      this.rowConferma = data;

      if (handler == "confermaOrdine") this.dialogConfermaOrdine();
      else if (handler == "ReadyToConfirm") this.prontoPerConferma();
      else if (handler == "Sollecito") this.sollecito();
      else if (handler == "mailAttivazione") this.mailAttivazione(data);
    },

    async prontoPerConferma() {
      let resConfirm = await this.$confirm.open(
        "Attiva conferma",
        "Vuoi rendere confermabile l'ordine  ?"
      );

      try {
        let res = await attivaConferma({
          IDFactory: this.rowConferma.FactoryID,
          IDBrand: this.rowConferma.IDBrand,
          OrdID: this.rowConferma.OrdID,
          LinkSrc: "",
          Confermato: resConfirm,
        });
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.caricaDati();
    },

    dialogConfermaOrdine() {
      this.dialogConferma = true;
    },

    sollecito() {
      this.dialogSollecito = true;
    },

    mailAttivazione(item) {
      this.dialogAttivazione = true;

      this.piva = item.CustVat;
      this.nome = item.CustName;
    },

    handleOrdineConf(val) {
      // NOTA: handleOrdineConf -> funzione richiamata quando è stato fatto SI/NO sulla conferma

      this.caricaDati();
    },

    chiudiConferma() {
      this.dialogConferma = false;
    },

    chiudiSollecito() {
      this.dialogSollecito = false;
    },

    chiudiDialogAttivazione() {
      this.dialogAttivazione = false;
    },

    selectRow(row) {
      this.selectedRow = row;
    },

    async caricaDettaglio(tab) {
      if (!!this.selectedRow == false) return;

      // attesa
      this.attesaDettaglio = true;

      // Reset
      this.datiDettaglio = [];
      // this.headersDettaglio = [];

      try {
        let par =
          tab +
          "/" +
          this.selectedRow.FactoryID +
          "/" +
          this.selectedRow.OrdID +
          this.$route.path;

        // Carico il dettaglio
        let res = await get_dettaglioConferma(par);

        this.datiDettaglio = res.data.dati;
        this.stiliDettaglio = res.data.stili;

        // Imposto i settaggi
        // this.setJsonSettings(res.data.stili, this.headersDettaglio);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      // Attendo lo script di rendering
      setTimeout(() => {
        this.attesaDettaglio = false;
      }, 600);
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getFiltroDatiTestata() {
      let arr = this.datiTestata;

      // Azienda
      if (this.filtroAziendaSel && this.filtroAziendaSel != "Tutti")
        arr = arr.filter((x) =>
          x.Brand.toLowerCase().includes(this.filtroAziendaSel.toLowerCase())
        );

      // Data consegna
      if (this.filtroDataConsegna)
        arr = arr.filter((x) => x.WCons <= parseInt(this.filtroDataConsegna));

      // Numero Ordine
      if (this.filtroNumOrdine)
        arr = arr.filter((x) =>
          x.OrdID.toLowerCase().includes(this.filtroNumOrdine.toLowerCase())
        );

      // Cliente / rif
      if (this.filtroCliente) {
        // NOTA: il campo cliente non c'è sulla role=5
        if (this.$store.getters["user/getRole"] == 5) {
          arr = arr.filter((x) =>
            x.RIFERIMENTO.toLowerCase().includes(
              this.filtroCliente.toLowerCase()
            )
          );
        } else {
          arr = arr.filter(
            (x) =>
              x.RIFERIMENTO.toLowerCase().includes(
                this.filtroCliente.toLowerCase()
              ) ||
              x.CustName.toLowerCase().includes(
                this.filtroCliente.toLowerCase()
              )
          );
        }
      }

      return arr;
    },
  },
};
</script>