

export default {


    methods: {
        getImg(valore, condizioni) {
            let res = ""
            if (condizioni && condizioni[valore]) {
                res = this.$load.getUrlImg() + "/" + condizioni[valore];
            }

            return res
        },
        getTooltip(dati, header) {
            if (header.toolTipText && Object.keys(header.toolTipText).length > 0) {
                let v = dati[header.value]
                return header.toolTipText[v];
            }
            else if (header.colTooltip) {
                return dati[header.colTooltip];
            }
        },

        formatValue(valore, tipo) {
            if (tipo == "Money") return "€ " + parseFloat(valore).toFixed(2);

            return valore;
        },


        onClickHandler(handler, data, header) {

            // Controllo funzione diretta javascript
            if (handler.startsWith("javascript:")) {
                let nomeCampo = this.$i2kFunctions.getValuesBetweenDelimeters(handler, "[", "]")
                let valore = data[nomeCampo]
                let s = handler.replace("[" + nomeCampo + "]", valore)
                eval(s)
                return
            }

            if (handler) this.$emit("onClickElement", handler, data);
        },


        getJson(valore) {
            let obj = JSON.parse(valore);

            let res = "";

            obj.forEach(value => {
                // Ciclo sulle chiavi del json
                for (var key in value) {
                    res += "<div>" + value[key] + "</div>";
                }
            });

            return res;
        },

        checkCond(dati, header, valore) {

            let indiceArr = dati[header.colCondition]

            let res = ""

            // Controllo se esiste la posizione dell' array, ossia se la condizione è stata trovata
            if (header.checkCond[indiceArr]) {
                res = header.checkCond[indiceArr][valore]
            }
            else {
                // NOTA: nel file json le iniziali sono maiuscole, mentre quelle che ho dichiarato io, sono minuscole
                res = header[this.toLowerFirstLetter(valore)]
            }

            if (valore == "OnClick") {
                this.onClickHandler(res, dati, header)
                return
            }

            return res
        },


        changeSort(item) {
            // Controllo sort attivo
            if (!item.sortable) return;

            if (this.sortBy === item.value) {
                this.sortDesc = !this.sortDesc;
            } else {
                this.sortBy = item.value;
                this.sortDesc = false;
            }

        },


        getColoreProgress(valore, condizioni) {

            if (condizioni && condizioni[valore]) {
                return condizioni[valore];
            }

            if (valore == 100) return "green accent-4";
            else if (valore >= 90) return "light-blue accent-4";
            else if (valore >= 80) return "light-blue accent-3";
            else if (valore >= 60) return "light-blue accent-2";
            else if (valore >= 40) return "orange darken-1";
            else if (valore > 0) return "amber darken-1";
            else return "grey lighten-1";

        },

        toLowerFirstLetter(valore) {
            // NOTA: nel file json le iniziali sono maiuscole, mentre quelle che ho dichiarato io, sono minuscole
            return valore.charAt(0).toLowerCase() + valore.substring(1)
        },



        setJsonSettings(stili, arr) {
            // Ciclo sugli stili
            for (var i = 0; i <= stili.length - 1; i++) {
                arr.push({
                    text: stili[i].VisName,
                    value: stili[i].OrigName,
                    width: stili[i].Width,
                    align: stili[i].Align || "left",
                    sortable: stili[i].Sortable,
                    hidden: stili[i].Hidden || false,
                    color: stili[i].BackGround || "",
                    dataType: stili[i].DataType || "",
                    style: stili[i].Style || "",
                    imageConditions: stili[i].ImageConditions || [],
                    toolTipText: stili[i].ToolTipText || [],
                    onClick: stili[i].OnClick || '',
                    iconName: stili[i].IconName || '',
                    colTooltip: stili[i].ColTooltip || '',
                    checkCond: stili[i].CheckCond || [],
                    colCondition: stili[i].ColCondition || 0,
                    progressConditions: stili[i].ProgressConditions || [],
                    label: stili[i].Label || false,
                    size: stili[i].Size || 0,
                });
            }
        },
    }


}
