<template src=".\registrazione.html">

</template>

<script>
import { mail_activation } from "@/api/api.account.js";
import loghi from "@/components/ui/Loghi.vue";

export default {
  components: {
    loghi
  },
  data: () => ({
    attesa: false,
    alertElaborazione: "",
    tipoMsg: "success",

    cred: {
      EMail: "",
      Piva: "",
      CodCliente: ""
    },

    rules: {
      required: value => !!value || "Il campo è obbligatorio.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
        return pattern.test(value) || "Indirizzo email non valido.";
      },
      codCliente: value => {
        return value.length == 6 || "Codice cliente deve essere 6 caratteri";
      },
      piva: value => {
        return (
          (/^\d+$/g.test(value) && value.length == 11) ||
          "Partita iva non valida"
        );
      }
    },

    tooltip: false
  }),

  methods: {
    async registra() {
      // Validazione
      if (this.$refs.formReg.validate() == false) {
        return;
      }

      this.attesa = true;

      try {
        let res = await mail_activation(this.cred);

        this.alertElaborazione = res.data;
        this.tipoMsg = "success";

        // Reset
        this.cred.EMail = "";
        this.cred.Piva = "";
        this.cred.CodCliente = "";
      } catch (error) {
        this.alertElaborazione = this.$i2kFunctions.getErrorMsg(error);
        this.tipoMsg = "warning";
      }

      this.attesa = false;
    },

    getPath() {
      return this.$load.getUrlImg();
    }
  }
};
</script>

