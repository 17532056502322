<template src="./statoOrdini.html"></template>

<script>
import { get_ordini, get_ordiniDettaglio } from "@/api/api.ordini.js";
import help from "@/components/dialog/Help.vue";
import mixin from "@/mixin/mixinTable.js";
import vtable from "@/components/ui/VTable";
import mailOrdine from "@/components/dialog/MailOrdine.vue";

export default {
  mixins: [mixin],

  components: {
    help,
    vtable,
    mailOrdine,
  },

  data: () => ({
    datiTestata: [],
    datiDettaglio: [],
    stili: [],
    stiliDettaglio: [],

    selectedRow: {},
    tabActive: null,

    attesaTestata: false,
    attesaDettaglio: false,

    filtriAziende: ["Tutti", "SoloLegno", "Stark", "Trix", "Visioni"],
    filtroAziendaSel: "Tutti",

    filtroStati: ["Tutti", "Completo", "Parziale", "Da fare"],
    filtroStatoSel: "Tutti",

    filtriVari: ["Tutti", "Ripristini"],
    filtroVariSel: "Tutti",

    filtroDataConsegna: "",
    filtroNumOrdine: "",
    filtroCliente: "",

    switchConsegnati: false,

    dialogHelp: false,
    dialogMail: false,

    rowMail: {},
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.datiTestata = [];
      this.datiDettaglio = [];
      this.selectedRow = [];

      this.attesaTestata = true;

      try {
        let filtro = this.filtriAziende.indexOf(this.filtroAziendaSel) - 1;
        filtro += "/" + (this.switchConsegnati ? 1 : 0);

        let res = await get_ordini(filtro);

        this.datiTestata = res.data.dati;
        this.stili = res.data.stili;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaTestata = false;
    },

    imgShowVariantiDettaglio(props) {
      return props.expanded ? "keyboard_arrow_down" : "keyboard_arrow_right";
    },

    selectRow(row) {
      this.selectedRow = row;

      this.caricaDettaglio();
    },

    apriHelp() {
      this.dialogHelp = true;
    },

    chiudiHelp() {
      this.dialogHelp = false;
    },

    getTab() {
      if (this.tabActive == 0) return "dettaglio";
      else if (this.tabActive == 1) return "trasporto";
      else if (this.tabActive == 2) return "ddt";
      else if (this.tabActive == 3) return "ripristini";
      else return "dettaglio";
    },

    async caricaDettaglio() {
      // attesa
      this.attesaDettaglio = true;

      // Reset
      this.datiDettaglio = [];

      try {
        // Carico il dettaglio
        let res = await get_ordiniDettaglio(
          this.getTab() +
            "/" +
            this.selectedRow.Factory +
            "/" +
            this.selectedRow.OrdID
        );

        this.datiDettaglio = res.data.dati;
        this.stiliDettaglio = res.data.stili;
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      // Attendo lo script di rendering
      setTimeout(() => {
        this.attesaDettaglio = false;
      }, 600);
    },

    onClick(handler, data) {
      //console.log(handler);
      //console.log(data);

      if (handler == "mailOrdine") this.mailOrdine(data);
    },

    mailOrdine(item) {
      this.rowMail = item;
      this.dialogMail = true;
    },

    chiudiMail() {
      this.dialogMail = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getFiltroDatiTestata() {
      let arr = this.datiTestata;

      // Filtri completi
      if (this.filtroStatoSel == "Completo")
        arr = arr.filter((x) => x.PercCompl == 100);
      else if (this.filtroStatoSel == "Parziale")
        arr = arr.filter((x) => x.PercCompl > 0 && x.PercCompl < 100);
      else if (this.filtroStatoSel == "Da fare")
        arr = arr.filter((x) => x.PercCompl == 0);

      // Filtri vari
      if (this.filtroVariSel == "Ripristini") {
        arr = arr.filter((x) => x.RiprState > 0);
      }

      // Data consegna
      if (this.filtroDataConsegna)
        arr = arr.filter((x) => x.WCons <= parseInt(this.filtroDataConsegna));

      // Numero Ordine
      if (this.filtroNumOrdine)
        arr = arr.filter((x) =>
          x.OrdID.toLowerCase().includes(this.filtroNumOrdine.toLowerCase())
        );

      // Cliente / rif
      if (this.filtroCliente) {
        // NOTA: il campo cliente non c'è sulla role=5
        if (this.$store.getters["user/getRole"] == 5) {
          arr = arr.filter((x) =>
            x.RIFERIMENTO.toLowerCase().includes(
              this.filtroCliente.toLowerCase()
            )
          );
        } else {
          arr = arr.filter(
            (x) =>
              x.RIFERIMENTO.toLowerCase().includes(
                this.filtroCliente.toLowerCase()
              ) ||
              x.CustName.toLowerCase().includes(
                this.filtroCliente.toLowerCase()
              )
          );
        }
      }

      return arr;
    },

    roleFiltri() {
      let r = this.$store.getters["user/getRole"];

      return r == 1 || r == 2 || r == 7;
    },
  },
};
</script>






