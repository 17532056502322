<template src="./login.html"></template>

<script>
import loghi from "@/components/ui/Loghi.vue";

export default {
  components: {
    loghi
  },

  data: function() {
    return {
      e1: true,

      cred: {
        username: "",
        password: ""
      },
      attesa: false
    };
  },

  methods: {
    async submit() {
      window.scrollTo(0, 0); // Scroll to top (serve sul mobile per vedere eventuali messaggi)

      // Validazione
      if (this.$refs.formLogin.validate() == false) {
        return;
      }

      // Attesa
      this.attesa = true;

      // Reset messaggi
      this.$toast.close();

      try {
        // Login
        await this.$store.dispatch("auth/login", this.cred);

        // Vado alla pagina riservata
        this.$router.push({ path: "/" });
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      // Fine attesa
      this.attesa = false;

      return false;
    }
  }
};
</script>




