<template>
  <div class="text-xs-center">
    <v-dialog v-model="getDialog" width="500">
      <v-card>
        <v-card-title class="headline" primary-title>Aiuto</v-card-title>

        <v-card-text>
          <div v-html="htmlHelp"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="chiudi">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get_help } from "@/api/api.user.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    htmlHelp: "",
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      try {
        let res = await get_help();

        this.htmlHelp = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },
};
</script>
