<template>
  <div class="text-xs-center">
    <v-dialog v-model="getDialog" width="500">
      <v-form ref="formAttiva" v-on:submit.prevent="confermaUtente()">
        <v-card>
          <v-card-title class="headline pb-0" primary-title
            >Attiva utente:</v-card-title
          >
          <v-card-title class="subheading pt-0">{{ nome }}</v-card-title>

          <v-card-text>
            <v-text-field
              label="E-Mail"
              outlined
              v-model="mail"
              required
              :rules="[() => !!mail || 'Il campo è obbligatorio']"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="primary" dark @click="chiudi">Annulla</v-btn>

            <v-spacer></v-spacer>
            <v-btn color="primary" dark type="submit" :loading="attesa"
              >Invia mail attivazione&nbsp;<v-icon>send</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mail_activation } from "@/api/api.account.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    piva: { type: String, default: "" },
    nome: { type: String, default: "" },
  },

  data: () => ({
    mail: "",
    attesa: false,
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;

      this.$nextTick(() => this.$refs.formAttiva.reset());

      this.mail = "";
    },

    chiudi() {
      this.$emit("chiudi");
    },

    async confermaUtente() {
      // Validazione
      if (this.$refs.formAttiva.validate() == false) {
        return;
      }

      let confirm = await this.$confirm.open(
        "Conferma",
        "Vuoi attivare l'utente ?"
      );

      if (!confirm) return;

      this.inviaMailAttivazione();
    },

    async inviaMailAttivazione() {
      this.attesa = true;

      try {
        let res = await mail_activation({ Piva: this.piva, EMail: this.mail });

        // NOTA: ho usato il toast siccome chiudo la dialog
        this.$toast.success("Utente attivato.");

        // Chiudo la dialog
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));

        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    },
  },
};
</script>
