import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/views/Main.vue'
import Login from '@/views/Login.vue'
import StatoOrdini from '@/views/StatoOrdini.vue'
import Home from '@/views/Home.vue'
import Profilo from '@/views/Profilo.vue'
import AttivazioneUtenti from '@/views/AttivazioneUtenti.vue'
import Feedback from '@/views/Feedback.vue'
import Download from '@/views/Download.vue'
import Statistiche from '@/views/Statistiche.vue'
import Maps from '@/views/Maps.vue'
import Fel from '@/views/fel.vue'
import Configuratore from '@/views/Configuratore.vue'
import OrdiniDaCOnf from '@/views/OrdiniDaConfermare.vue'

import PwdReset from '@/views/account/PasswordReset.vue'
import AttivaAccount from '@/views/account/ActivateAccount.vue'
import Registra from '@/views/account/Registrazione.vue'
import Preventivatore from "@/views/preventivi/Preventivatore.vue"
import Anagrafiche from "@/views/Anagrafiche.vue"

import storage from '@/services/storage.js'
import confermaOrdine from "@/views/ext/PaginaConfermaOrdine"

Vue.use(Router)

const routerOptions = [{
  path: '/',
  component: Main,
  meta: {
    checkAuth: true
  },
  children: [{
    path: '/',
    component: Home,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/statoordini',
    component: StatoOrdini,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/profilo',
    component: Profilo,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/attivazioni',
    component: AttivazioneUtenti,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/feedback',
    component: Feedback,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/statistiche',
    component: Statistiche,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/download',
    component: Download,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/maps',
    component: Maps,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/configuratore',
    component: Configuratore,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/orddaconf',
    component: OrdiniDaCOnf,
    meta: {
      checkAuth: true
    }
  }, {
    path: '/preventivatore',
    component: Preventivatore,
    meta: {
      checkAuth: true
    }
  }, {
    path: '/anagrafica',
    component: Anagrafiche,
    meta: {
      checkAuth: true
    }
  }]
},
{
  path: '/login',
  component: Login,
},
{
  path: '/fatturaelettronica',
  name: 'Fel',
  component: Fel
},
{
  path: '/passwordreset',
  component: PwdReset,
},
{
  path: '/activateaccount',
  component: AttivaAccount,
},
{
  path: '/register',
  component: Registra,
},
{
  path: '/confermaordine',
  component: confermaOrdine,
},
{
  path: "*", // Pagine non trovate (redirect su /)
  beforeEnter: (to, from, next) => {
    next({
      path: '/'
    })
  }
}
]


const rou = new Router({
  mode: 'history',
  routes: routerOptions
})


rou.beforeEach((to, from, next) => {

  // Controllo se è richiesta l'autenticazione
  if (to.meta.checkAuth && !storage.checkAuth()) {

    next({
      path: '/login'
    })

    return

  }
  // Controllo pagina login
  else if (to.path == "/login" && storage.checkAuth()) {

    // Se il login è stato fatto, vado alla pagina riservata
    next({
      path: '/'
    })
    return
  }

  next();

})



export default rou;