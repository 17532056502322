<template src="./profilo.html"></template>

<script>
import { get_datiprofiloUtente, save_datiprofiloUtente } from "@/api/api.user.js";
export default {
  data: () => ({
    dati: { username: "", password: "", password2: "", displayName: "" },
    attesa: false
  }),

  methods: {
    caricaDati() {
      this.attesa = true;

      // Carico il dettaglio
      get_datiprofiloUtente()
        .then(res => {
          this.dati.username = res.data.username;
          this.dati.eMail = res.data.eMail;
          this.dati.displayName = res.data.displayName;

          this.attesa = false;
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.attesa = false;
        });
    },

    salva() {
      // Validazione
      if (this.$refs.form.validate() == false) {
        return;
      }

      this.$confirm
        .open("Salva", "Vuoi salvare i dati del profilo utente ?")
        .then(confirm => {
          if (!confirm) return;

          this.salvaDati();
        })
        .catch(err => {
          alert("err");
        });
    },

    salvaDati() {
      this.attesa = true;

      // Salva
      save_datiprofiloUtente(this.dati)
        .then(res => {
          console.log(res);
          this.attesa = false;

          this.$toast.success("Dati salvati correttamente");
        })
        .catch(err => {
          this.attesa = false;

          console.log(this.$i2kFunctions.getErrorMsg(err));
        });
    }
  },

  mounted() {
    this.caricaDati();
  }
};
</script>
