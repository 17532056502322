import request from '@/services/interceptor.js'

// Download
export const get_info_ordine = (par) => {
    return request({
        url: '/ext/ordine/info' + par,
        method: 'get',
        loadingBar: true
    })
};

export const get_datiConfermaOrdine = (par) => {
    return request({
        url: '/ext/ordine/conferma/' + par,
        method: 'get'
    })
};

export const confermaOrdine = (data) => {
    return request({
        url: '/ext/ordine/conferma',
        method: 'post',
        data: data
    })
};