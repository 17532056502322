<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="700px"
    v-model="getDialog"
  >
    <v-card>
      <v-card-title id="toolbar">
        <v-row>
          <!-- Close -->
          <div>
            <v-icon id="close" @click="chiudi()" dark fab elevation="0"
              >close</v-icon
            >
          </div>
          <!-- Title -->
          <div>
            <span id="title"></span>
          </div>
          <v-spacer></v-spacer>
          <!-- Salva -->
          <div>
            <v-btn @click="salvaDati()" id="pulsanteSalva" dark elevation="0">
              SALVA
              <v-icon class="ml-1" dark> save </v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-row>
          <v-col class="col-6">
            <v-text-field
              v-model="item.raG_SOC_CF"
              color="#2196F3"
              outlined
              clearable
              label="Ragione Sociale"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.indI_CF"
              color="#2196F3"
              outlined
              clearable
              label="Indirizzo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.caP_CF"
              color="#2196F3"
              outlined
              clearable
              label="CAP"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.comunE_CF"
              color="#2196F3"
              outlined
              clearable
              label="Comune"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.provinciA_CF"
              color="#2196F3"
              outlined
              clearable
              label="Provincia"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.statO_CF"
              color="#2196F3"
              outlined
              clearable
              label="Stato"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.teL_CF"
              color="#2196F3"
              outlined
              clearable
              label="Telefono"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.faX_CF"
              color="#2196F3"
              outlined
              clearable
              label="Fax"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.e_MAIL_CF"
              color="#2196F3"
              outlined
              clearable
              label="EMail"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.notE_CF"
              color="#2196F3"
              outlined
              clearable
              label="Note:"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.p_IVA_CF"
              color="#2196F3"
              outlined
              clearable
              label="Partita IVA"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.coD_FISC_CF"
              color="#2196F3"
              outlined
              clearable
              label="Codice Fiscale"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.cellularE_CF"
              color="#2196F3"
              outlined
              clearable
              label="Cellulare"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.raG_SOC_BREVE"
              color="#2196F3"
              outlined
              clearable
              label="Ragione Sociale breve"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="dato">
          <v-col class="col-6">
            <v-text-field
              v-model="item.e_MAIL_PEC"
              color="#2196F3"
              outlined
              clearable
              label="Pec"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              v-model="item.e_MAIL_2_CF"
              color="#2196F3"
              outlined
              clearable
              label="EMail (riserva)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  
  <script>
import { set_anagrafiche } from "@/api/api.anagrafiche.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    utente: { type: Number, default: 0 },
    modifica: { type: Object, default: null },
    editAnagrafica: { type: Boolean, default: false },
  },

  data: () => ({
    item: {},
  }),

  methods: {
    async salvaDati() {
      if (this.item.raG_SOC_CF != null) {
        //controllo se è in new o in edit
        let res = await set_anagrafiche(this.item);

        if (res.data == "OK") {
          this.$toast.info("Dati salvati");
          this.chiudi();
        } else this.$toast.alert("Impossibile salvare i dati");
      } else this.$toast.alert("Ragione sociale mancante");
    },

    chiudi() {
      this.item = {};
      this.$emit("chiudi");
    },
  },

  mounted() {},

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    modifica() {
      this.item = this.modifica;
    },
  },
};
</script>
  
<style scoped src="..\..\assets\css\preventivi\dialog\nuovaAnagrafica.css">
</style>