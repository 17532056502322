<template src="./passwordReset.html"></template>

<script>
import { send_passwordMail, save_newPassword } from "@/api/api.account.js";

import loghi from "@/components/ui/Loghi.vue";

export default {
  components: {
    loghi
  },

  data: () => ({
    e1: false,
    disabilitaBtn: false,
    alertElaborazione: "",
    tipo: "success",
    reset: false,
    cred: {
      EMail: "",
      Password: "",
      ConfermaPassword: "",
      Code: ""
    },
    OK: false
  }),

  methods: {
    sendMail() {
      // Validazione
      if (this.$refs.formMail.validate() == false) {
        return;
      }

      // Reset
      this.alertElaborazione = "";
      this.disabilitaBtn = true;

      // Invio mail
      send_passwordMail({ email: this.cred.EMail })
        .then(res => {
          this.tipo = "success";
          this.alertElaborazione =
            "Abbiamo inviato un messaggio al tuo indirizzo e-mail con il link per la reimpostazione della password.";

          this.disabilitaBtn = false;
          this.OK = true;
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.disabilitaBtn = false;
          this.tipo = "warning";
          this.alertElaborazione = this.$i2kFunctions.getErrorMsg(err);
        });
    },

    resetPwd() {
      // Validazione
      if (this.$refs.formPwd.validate() == false) {
        return;
      }

      // Controllo presenza codice
      if (!this.checkCode()) return;

      // Reset
      this.alertElaborazione = "";
      this.disabilitaBtn = true;

      this.cred.Code = this.$route.query.code;

      // Reset pwd
      save_newPassword(this.cred)
        .then(res => {
          this.tipo = "success";
          this.alertElaborazione =
            "Operazione completata. Premi il tasto 'Indietro' per tornare al login.";

          this.disabilitaBtn = false;
          this.OK = true;
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.disabilitaBtn = false;
          this.tipo = "warning";
          this.alertElaborazione = err.response
            ? err.response.data.message
            : err.message;
        });
    },

    checkCode() {
      if (this.$route.query.code && this.$route.query.code.length > 0) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    this.reset = this.checkCode();
  }
};
</script>
