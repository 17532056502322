import { render, staticRenderFns } from "./NuovaAnagrafica.vue?vue&type=template&id=fcf75638&scoped=true&"
import script from "./NuovaAnagrafica.vue?vue&type=script&lang=js&"
export * from "./NuovaAnagrafica.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/preventivi/dialog/nuovaAnagrafica.css?vue&type=style&index=0&id=fcf75638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcf75638",
  null
  
)

export default component.exports