<template>
  <v-navigation-drawer
    width="220"
    :clipped="$vuetify.breakpoint.mdAndUp"
    mobile-breakpoint="400"
    app
    v-model="leftBarVis"
    color="#EEE"
  >
    <v-list-item>
      <img
        :src="getPath() + '/logo_aip.png'"
        height="65"
        class="my-4 ml-4"
        color="primary"
      />
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pa-2 mt-0" nav>
      <template v-for="(item, i) in menuItems">
        <v-list-item
          :key="i"
          @click="gotoPage(item)"
          :style="{
            backgroundColor:
              item.link === $route.path ? '#782327' : 'transparent',
          }"
          class="ma-0 mb-2"
        >
          <v-list-item-avatar>
            <v-icon :color="item.link === $route.path ? '#fff' : '#000'">{{
              item.img
            }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="font-weight-regular subtitle-2"
              :style="{ color: item.link === $route.path ? '#fff' : '#000' }"
              v-html="item.text"
            >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block rounded @click="esci" :loading="waitLogout">
          <v-icon left>logout</v-icon>Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>


<script>
import { log_activity } from "@/api/api.user.js";

export default {
  props: {
    menuItems: {
      type: Array,
    },
  },
  data: () => ({
    leftBarVis: null,

    waitLogout: false,
  }),

  methods: {
    esci() {
      this.waitLogout = true;
      this.$emit("esci");
    },

    gotoPage(item) {
      if (item.link == "@esci") this.esci();
      else {
        this.$router.push({ path: item.link });
      }

      // Controllo log
      if (item.logActivity) this.attivita(item.menuId);
    },

    async attivita(menuId) {
      // Escludo il debug
      if (this.$i2kFunctions.isDebug()) return;

      try {
        await log_activity(menuId);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    getPath() {
      return this.$load.getUrlImg();
    },
  },
};
</script>

