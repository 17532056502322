<template src="./preventivatore.html"></template>

<script>
import { get_elencoPreventivi } from "@/api/api.preventivi.js";
import cercaPreventivi from "@/components/dialog/preventivi/CercaPreventivi.vue";
import infoTestata from "@/components/dialog/preventivi/InfoTestata.vue";

export default {
  components: {
    cercaPreventivi,
    infoTestata,
  },

  data: () => ({
    menu: false,

    search: "",
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    numPrev: "202301",
    listaMenu: [
      { Nome: "Elimina", icon: "delete", action: "elimina" },
      { Nome: "Ricarica", icon: "refresh", action: "refresh" },
    ],
    tab: 0,
    cliente: false,
    dialogCercaPreventivi: false,
    dialogInfoTestata: false,

    elencoPreventivi: [],
    headersPreventivi: [
      { text: "", value: "" },
      { text: "", value: "" },
      { text: "Posizione", value: "pos" },
      { text: "Riferimento", value: "riferimento" },
      { text: "Serie", value: "serie" },
      { text: "Q.tà", value: "qta" },
      { text: "L", value: "l" },
      { text: "H", value: "h" },
      { text: "S", value: "s" },
      { text: "Prezzo", value: "prezzo" },
    ],

    totale: {
      qta: 0,
      totale: 0,
      extraSconto: 0,
      totaleGenerale: 0,
    },

    alert: {
      message: "",
      severity: "", //warning-error-success
      icona: "", //warning-error-check_circle_outline
      show: false,
    },

    testata: {
      note: "",
      cliente: "",
      data: "",
      riferimento: "",
    },
  }),

  methods: {
    refresh() {
      console.log("refresh");
      //this.caricaDati();
    },

    caricaDati() {
      this.getElencoPreventivi();
    },

    async getElencoPreventivi() {
      let res = await get_elencoPreventivi();
      if (res.data.lenght > 0) this.elencoPreventivi = res.data;
    },

    esegui(op) {
      switch (op) {
        case "elimina":
          this.eliminaPreve();
          break;
        case "refresh":
          this.refresh();
          break;

        default:
          break;
      }
    },

    //dialog ricerca
    apriDialogRicerca(value) {
      this.cliente = value;
      this.dialogCercaPreventivi = true;
    },

    chiudiDialogRicerca() {
      this.dialogCercaPreventivi = false;
    },

    //dialog testata
    apriDialogTestata() {
      this.dialogInfoTestata = true;
    },

    chiudiDialogTestata() {
      this.dialogInfoTestata = false;
    },

    eliminaPreve() {
      console.log("elimina");
    },

    cambiaPreve(action) {},

    //dettaglio preventivo
    nuovoRigo() {},

    modificaRigo() {},

    eliminaRigo() {},

    duplicaRigo() {},

    getMessageStyle() {
      if (this.alert.severity == "warning") return "background-color: #FFC400";
      if (this.alert.severity == "error") return "background-color: #E53935";
      if (this.alert.severity == "success") return "background-color: #43A047";
    },
  },

  mounted() {
    this.caricaDati();
  },
};
</script>

<style scoped src="..\..\assets\css\preventivi\preventivatore.css">
</style>