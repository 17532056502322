import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import VTooltip from 'v-tooltip'

import Load from "./LOAD.js"

import Functions from "i2kjslibrary"
import Toast from 'i2kjslibrary/plugins/vuetify/toast/toast';
import Confirm from 'i2kjslibrary/plugins/vuetify/confirm/confirm';
import Wait from 'i2kjslibrary/plugins/vuetify/wait/wait';
import Pwd from 'i2kjslibrary/plugins/vuetify/passwordInput/passwordInput';

import VueGtag from "vue-gtag";

import "./assets/css/style.css"

Vue.config.productionTip = false

// Vuetify
Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#782327'
      },
      dark: {
        primary: '#782327',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
}

// Tooltip
Vue.use(VTooltip)

const isProd = process.env.NODE_ENV === 'production'


// Plugins
Vue.use(Toast, {
  version: 2
})
Vue.use(Confirm, {
  version: 2
})
Vue.use(Wait, {
  version: 2
})
Vue.use(Pwd, {
  version: 2,
  password: 'Delimage'
})
Vue.use(VueGtag, {
  config: {
    id: "UA-47277496-1"
  },
  enabled: isProd
}, router);

// Prototype
Vue.prototype.$i2kFunctions = Functions;
Vue.prototype.$load = Load;

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify(opts)
}).$mount('#app')