<template>
  <div class="text-xs-center">
    <v-dialog v-model="getDialog" width="500">
      <v-form ref="formInvio" v-on:submit.prevent="invia() ">
        <v-card>
          <v-card-title class="headline pb-0" primary-title>
            Richiesta informazioni relative all'ordine :
            <span class="subheading">
              {{rowOrdine.OrdID}}
              <br />
              {{rowOrdine.CustName}}
            </span>
          </v-card-title>

          <v-card-text>
            <v-select :items="aree" outline label="Area" v-model="areaSel"></v-select>
            <v-text-field
              v-model="dati.oggetto"
              label="Oggetto"
              full-width
              hide-details
              maxlength="200"
              required
              outline
              :rules="[() => dati.oggetto && dati.oggetto.length > 0 || 'Il campo è obbligatorio']"
            ></v-text-field>
            <v-divider class="py-2"></v-divider>
            <v-textarea
              v-model="dati.testo"
              label="Messaggio"
              counter
              maxlength="4000"
              required
              filled
              height="200"
              :rules="[() => dati.testo && dati.testo.length > 0 || 'Il campo è obbligatorio']"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" dark  @click="chiudi">Annulla</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark  type="submit" :disabled="attesa" :loading="attesa">
              Invia
              <v-icon class="ml-2">send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { sendMail_ordine } from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, required: true, default: false },
    rowOrdine: { type: Object, default: () => ({}) }
  },

  data: () => ({
    dati: { oggetto: "", testo: "", brand: "", area: 0 },
    attesa: false,
    aree: ["Informazioni produzione", "Logistica", "Amministrazione"],
    areaSel: ""
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;

      this.dati.oggetto = "Richiesta informazioni :" + this.rowOrdine.OrdID;
      this.dati.brand = this.rowOrdine.Brand;
      this.areaSel = this.aree[0];

      //console.log(this.rowOrdine);
    },

    invia() {
      // Validazione
      if (this.$refs.formInvio.validate() == false) {
        return;
      }

      this.dati.area = this.aree.findIndex(f => f == this.areaSel) + 1;

      this.attesa = true;

      sendMail_ordine(this.dati)
        .then(res => {
          //console.log(res.data);

          this.attesa = false;

          this.$toast.success("E-Mail inviata correttamente");

          // Chiudo la dialog
          this.chiudi();
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));

          this.attesa = false;
        });
    },

    chiudi() {
      this.$emit("chiudi");
    }
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getDialog: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        if (!value) {
          this.chiudi();
        }
      }
    }
  },

  watch: {
    dialog(val) {
      this.caricaDati();
    }
  }
};
</script>
