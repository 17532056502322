


import request from '@/services/interceptor.js'

// Fatturazione elettronica
export const get_fel = (par) => {
    return request({
        url: '/fel/utente/' + par,
        method: 'get',
        loadingBar: true
    })
};
export const save_fel = (par) => {
    return request({
        url: '/fel/salva/' + par,
        method: 'post',
        loadingBar: true
    })
}

