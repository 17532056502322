
const app = {
    namespaced: true,

    state: {
        loading: false,
        message: '',
    },

    mutations: {

        SET_LOADING(state, vis) {
            state.loading = vis
        },


        SET_MSG(state, data) {
            state.message = data

        },

    },


}

export default app