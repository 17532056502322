import request from '@/services/interceptor.js'

export const get_anagrafiche = () => {
    return request({
        url: '/anagrafiche/list',
        method: 'get',
        loadingBar: true
    })
};

export const set_anagrafiche = (data) => {
    return request({
        url: '/anagrafiche/insert',
        method: 'post',
        data: data,
        loadingBar: true
    })
};

export const elimina_anagrafica = (data) => {
    return request({
        url: '/anagrafiche/delete/',
        method: 'post',
        data: data,
        loadingBar: true
    })
};