<template src="./home.html">
    
</template>

<script>


export default {
    
    methods:{
        getPath(){
            return this.$load.getUrlImg()
        }
    }
}
</script>

